(function () {
  'use strict';

  angular
    .module('people.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('people.edit', {
        url: '/:personId/edit',
        templateUrl: 'people/edit/edit.tpl.html',
        controller: 'EditCtrl',
        controllerAs: 'vm'
      })
      .state('people.add', {
        url: '/add',
        templateUrl: 'people/edit/edit.tpl.html',
        controller: 'EditCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
